import React from "react";

import { Container, makeStyles, createStyles } from "@material-ui/core";
import { createInitialVersions } from "../../utils/versionUtilFuncs";
import Title from "../dashboard/Title";
import { CompanyInfoForm } from "./company-info-form";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyNew() {
  const classes = useStyles();
  const history = useHistory();

  const submitCustomer = async (company) => {
    try {
      const fetch = await createInitialVersions("company", {
        ...company,
        version: "v0",
        approved: false,
        approvedBy: "",
        approvedOn: null,
      });
      const id = fetch.id;
      history.push(`/company/${id}`);
    } catch (error) {
      console.log("error: ", error);
    }
  };
  return (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Title>Customer Information</Title>
      </div>
      <div className={classes.container}>
        <CompanyInfoForm submit={submitCustomer} />
      </div>
    </Container>
  );
}
