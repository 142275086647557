import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Button,
  MenuItem,
  TextField,
  Select,
  Snackbar,
  Breadcrumbs,
  Link,
  Typography,
  Table,
  Checkbox,
} from "@material-ui/core";
import { Link as NavLink } from "react-router-dom";
import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { ProgramInfoForm } from "./program-info-form";
import {
  updateProgramVersioned,
  updateServiceVersioned,
} from "../../../graphql/mutations";
import {
  getProgramVersioned,
  listServiceTypes,
} from "../../../graphql/queries";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import PropTypes from "prop-types";

import * as _ from "lodash";
import Conditions from "../../../components/conditions/conditions";
import {
  getCompanyDiscountCodes,
  getCompanyPrograms,
} from "../../../integrations/realgreen/api_calls";
import { SelectValidator } from "react-material-ui-form-validator";
import {
  createNewVersion,
  createInitialVersions,
  createInitialId,
} from "../../../utils/versionUtilFuncs";
import { useHistory } from "react-router-dom";
import { fetchAndSetActiveCompanyContext } from "../../../contexts/activeCompany.contexts";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    addBtn: {
      position: "relative",
      float: "right",
      marginRight: 15,
    },
    servicesContainer: {
      width: "70%",
      minHeight: 100,
      maxHeight: 500,
      border: "1px inset black",
      padding: 10,
      overflow: "auto",
      marginTop: 10,
    },
  }),
);

export function CompanyProgramDetail() {
  const { companyId, programId } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const [program, setProgram] = useState({});
  const [priceTables, setPriceTables] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [services, setServices] = useState([]);
  const [realGreenPrograms, setRealGreenPrograms] = useState([]);
  const [realGreenDiscountCodes, setRealGreenDiscountCodes] = useState([]);
  const [crmSolution, setCrmSolution] = useState("none");

  const serviceTemplate = {
    serviceName: "New Service",
    roundNumber: 0,
    priceTableId: null,
  };

  const addService = () => {
    const add = Object.assign({}, serviceTemplate);
    const payload = [...services, add];
    setServices(payload);
  };

  const fetchProgram = useCallback(async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(getProgramVersioned, { id: programId, version: "v0" }),
      );
      setProgram(fetch.data.getProgramVersioned);
      if (fetch.data.getProgramVersioned.services.items) {
        setServices(
          fetch.data.getProgramVersioned.services.items.sort((i) => i.round),
        );
      }
      setConditions(
        fetch.data.getProgramVersioned.conditions
          ? JSON.parse(fetch.data.getProgramVersioned.conditions)
          : [],
      );
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [programId]);

  const deleteProgramCb = async () => {
    // eslint-disable-next-line no-restricted-globals
    const ask = confirm("Are you Sure?");
    try {
      if (ask) {
        await API.graphql(
          graphqlOperation(updateProgramVersioned, {
            input: { id: programId, version: "v0", isDeleted: true },
          }),
        );
        history.replace(`/company/${companyId}/programs`);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const fetchCompany = useCallback(async () => {
    try {
      const activeCompany = await fetchAndSetActiveCompanyContext({
        companyId,
      });
      setPriceTables(activeCompany.priceTables.items);
      setCrmSolution(activeCompany.crmSolution || "none");
      if (activeCompany.crmSolution === "real-green") {
        const apiKey = activeCompany.dataServices.realGreenAPIKey;
        const rgprograms = await getCompanyPrograms(apiKey);
        const rgcodes = await getCompanyDiscountCodes(apiKey);
        setRealGreenPrograms(rgprograms);
        setRealGreenDiscountCodes(rgcodes);
      }

      setLoaded3(true);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [companyId]);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Program Saved");
  const [conditions, setConditions] = useState([]);
  const [displayDate, setDisplayDate] = useState("");
  const [modifiedRound, setModifiedRound] = useState(null);
  const [newServiceName, setNewServiceName] = useState("");
  const [newTableId, setNewTableId] = useState("");

  const fetchServiceTypes = useCallback(async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(listServiceTypes, { limit: 1000 }),
      );
      setServiceTypes(fetch.data.listServiceTypes.items);
      setLoaded2(true);
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  useEffect(() => {
    // fetch customer record here
    fetchProgram();
    fetchServiceTypes();
    fetchCompany();
  }, [fetchProgram, fetchServiceTypes, fetchCompany]);

  const updateProgramCb = async (program) => {
    const fullPayload = {
      ...program,
      programCompanyId: companyId,
      conditions: JSON.stringify(conditions),
    };

    // eslint-disable-next-line no-unused-vars
    const { company, services, programs, ...payload } = fullPayload;

    if (!payload.excludedZips || payload.excludedZips === "") {
      payload.excludedZips = "null";
    }
    try {
      await createNewVersion(payload.id, "program", payload);
      setSnackMessage("Program Saved");
      openSnack(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const deleteServiceCb = async (service) => {
    try {
      if (service.id) {
        await API.graphql(
          graphqlOperation(updateServiceVersioned, {
            input: { id: service.id, version: "v0", isDeleted: true },
          }),
        );
        setSnackMessage("Service Deleted");
        openSnack(true);
        const filtered = services.filter((i) => i.id !== service.id);
        setServices(filtered);
      } else {
        setSnackMessage("Service Deleted");
        openSnack(true);
        const filtered = services.filter((i) => i.id);
        setServices(filtered);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const addServiceCb = async (service) => {
    setNewTableId(service.priceTableId);
    setNewServiceName(service.serviceName);
    setModifiedRound(service.roundNumber);
    setDisplayDate(service.cutOffDate);
    let newSnackMessage;
    try {
      if (service.id) {
        await createNewVersion(service.id, "service", service);
        newSnackMessage = "Service Updated";
      } else {
        const payload = Object.assign({}, service, {
          serviceProgramId: programId,
          id: createInitialId(programId),
        });
        createInitialVersions("service", payload);
        newSnackMessage = "Service Created";
      }
      setSnackMessage(newSnackMessage);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const rounds = [...Array(53).keys()];
  rounds.shift();

  const key = "generator-form";

  const [generator, setGenerator] = useState({
    base: "service",
    startRound: 1,
    endRound: null,
    priceTableId: priceTables?.length ? priceTables[0].id : null,
  });

  const generateServices = () => {
    for (let i = generator.startRound; i < generator.endRound + 1; i++) {
      const payload = {
        serviceName: `${generator.base}-${i}`,
        roundNumber: i,
        priceTableId: generator.priceTableId,
      };
      addServiceCb(payload);
    }
  };

  return loaded && loaded2 && loaded3 ? (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/programs`}>
          Programs
        </Link>
        <Typography color="textPrimary">{program.programName}</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>{program.programName}</Title>
        <Button
          className={classes.newButton}
          color="primary"
          variant="contained"
          component={NavLink}
          to={`/company/${companyId}/program/${programId}/probing-questions`}
        >
          Setup Probing Questions
        </Button>
        <Button
          className={classes.newButton}
          style={{ position: "relative", float: "right" }}
          color="secondary"
          variant="contained"
          onClick={deleteProgramCb}
        >
          Delete Program
        </Button>
        <ProgramInfoForm
          submit={updateProgramCb}
          program={program}
          crmPrograms={realGreenPrograms}
          crmDiscounts={realGreenDiscountCodes}
          crmSolution={crmSolution}
          priceTables={priceTables || []}
        />
      </div>
      {!program.useRealGreenPricingEngine ? (
        <div>
          <div className={classes.container}>
            <Table>
              <TableBody>
                <TableRow key={key}>
                  <TableCell>
                    <TextField
                      id="serviceName"
                      label="Service Name"
                      value={generator.base}
                      style={{ margin: 8, width: 300 }}
                      placeholder="Mowing Service"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        const payload = Object.assign({}, generator, {
                          base: e.target.value,
                        });
                        setGenerator(payload);
                      }}
                      validators={["required"]}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      labelid="startRound"
                      id="startRound"
                      style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
                      value={generator.startRound}
                      onChange={(e) => {
                        const payload = Object.assign({}, generator, {
                          startRound: e.target.value,
                        });
                        setGenerator(payload);
                      }}
                      validators={["required"]}
                    >
                      {rounds.map((i) => {
                        const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
                        return (
                          <MenuItem key={key} value={i}>
                            {i}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      labelid="roundNumber"
                      id="endRound"
                      style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
                      value={
                        generator.endRound === null ? "" : generator.endRound
                      }
                      onChange={(e) => {
                        const payload = Object.assign({}, generator, {
                          endRound: e.target.value,
                        });
                        setGenerator(payload);
                      }}
                      validators={["required"]}
                    >
                      {rounds.map((i) => {
                        const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
                        return (
                          <MenuItem key={key} value={i}>
                            {i}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      labelid="priceTableId"
                      id="priceTableId"
                      style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
                      value={
                        generator.priceTableId === null
                          ? ""
                          : generator.priceTableId
                      }
                      onChange={(e) => {
                        const payload = Object.assign({}, generator, {
                          priceTableId: e.target.value,
                        });
                        setGenerator(payload);
                      }}
                      validators={["required"]}
                    >
                      {priceTables?.map((i) => {
                        const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
                        return (
                          <MenuItem key={key} value={i.id}>
                            {i.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={generateServices}
                    >
                      Generate
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className={classes.container}>
            <Button
              className={classes.addBtn}
              color="primary"
              variant="contained"
              onClick={addService}
            >
              Add Service
            </Button>
            <Title>Services</Title>
            <ServicesList
              serviceTypes={serviceTypes}
              newServiceName={newServiceName}
              newTableId={newTableId}
              modifiedRound={modifiedRound}
              displayDate={displayDate}
              services={services}
              submit={addServiceCb}
              priceTables={priceTables}
              deleteServiceCb={deleteServiceCb}
            />
          </div>
        </div>
      ) : null}
      <div className={classes.container}>
        <Conditions
          conditions={conditions}
          updatePriceTableConditions={(conditions) => {
            setConditions(conditions);
          }}
        />
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={6000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Container>
  ) : null;
}

function ServicesList(props) {
  const {
    services,
    deleteServiceCb,
    displayDate,
    modifiedRound,
    newServiceName,
    newTableId,
  } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Service Name</TableCell>
          <TableCell>Round</TableCell>
          <TableCell>Price Table</TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {_.sortBy(services, "roundNumber").map((row) => {
          // let checkedDate = row.roundNumber === 0 || row.roundNumber === props.modifiedRound ?
          //   displayDate :
          //   null;
          let checkedDate;
          let checkedRound;
          let checkedServiceName;
          let checkedTableId;

          if (
            row.roundNumber === 0 ||
            row.roundNumber === props.modifiedRound
          ) {
            checkedDate = displayDate;
            checkedRound = modifiedRound;
            checkedServiceName = newServiceName;
            checkedTableId = newTableId;
          } else {
            checkedDate = null;
            checkedRound = null;
            checkedServiceName = null;
            checkedTableId = null;
          }
          const key = `service-item-${Math.round(Math.random() * 100000)}`;
          return (
            <ServiceItem
              service={row}
              {...props}
              checkedDate={checkedDate}
              checkedRound={checkedRound}
              checkedServiceName={checkedServiceName}
              key={key}
              deleteServiceCb={deleteServiceCb}
              checkedTableId={checkedTableId}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

function ServiceItem(props) {
  const {
    service,
    priceTables,
    deleteServiceCb,
    checkedDate,
    checkedRound,
    checkedServiceName,
    checkedTableId,
  } = props;

  const rounds = [...Array(53).keys()];
  rounds.shift();

  const [item, setItem] = useState(
    service || {
      serviceName: "New Service",
      roundNumber: 0,
      priceTable: null,
    },
  );

  const updateForm = (e) => {
    const key = e.target.id;
    const val = e.target.value;
    const payload = {};
    payload[key] = val;
    setItem({ ...item, ...payload });
  };

  const submitForm = () => {
    props.submit(item);
  };

  const key = `service-row-item-${Math.floor(Math.round() * 100000)}`;

  const submitDelete = () => {
    deleteServiceCb(item);
  };

  return (
    <TableRow key={key}>
      <TableCell>
        <TextField
          id="serviceName"
          label="Service Name"
          value={checkedServiceName || item.serviceName}
          style={{ margin: 8, width: 100 }}
          placeholder="Mowing Service"
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          InputLabelProps={{
            shrink: true,
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <Select
          labelid="roundNumber"
          id="roundNumber"
          style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
          value={checkedRound || item.roundNumber}
          onChange={(e) => {
            const payload = Object.assign({}, item, {
              roundNumber: e.target.value,
            });
            setItem(payload);
          }}
          validators={["required"]}
        >
          {rounds.map((i) => {
            const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
            return (
              <MenuItem key={key} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell>
        <Select
          labelid="priceTableId"
          id="priceTableId"
          style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
          value={checkedTableId || item.priceTableId}
          onChange={(e) => {
            const payload = Object.assign({}, item, {
              priceTableId: e.target.value,
            });
            setItem(payload);
          }}
          validators={["required"]}
        >
          {priceTables?.map((i) => {
            const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
            return (
              <MenuItem key={key} value={i.id}>
                {i.name}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          id="date"
          label="Cut-Off Date"
          type="date"
          value={checkedDate || item.cutOffDate}
          onChange={(e) => {
            const payload = Object.assign({}, item, {
              cutOffDate: e.target.value,
            });
            setItem(payload);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TableCell>
      <TableCell>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={submitForm}
        >
          Save
        </Button>
        <Button color="secondary" variant="contained" onClick={submitDelete}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

ServiceItem.propTypes = {
  service: PropTypes.object,
  submit: PropTypes.func,
  deleteSerivceCb: PropTypes.func,
};

export const RGDiscountRoundList = (props) => {
  const [rounds, setRounds] = useState(props.rounds);
  const [discountCodes] = useState(props.discountCodes);
  useEffect(() => {
    setRounds(props.rounds);
  }, [props.rounds]);

  return (
    <Table>
      <TableHead>
        <TableCell>Round</TableCell>
        <TableCell>Discount Code</TableCell>
        <TableCell>Lower Square Footage Range</TableCell>
        <TableCell>Upper Square Footage Range</TableCell>
        <TableCell>Sliding Scale</TableCell>
        <TableCell />
      </TableHead>
      <TableBody>
        {rounds?.map((i, index) => {
          const id = Math.random() * 10000;
          const key = `rowkey_${id}`;
          const textkey = `textkey_${id}`;
          const codekey = `codekey_${id}`;
          return (
            <TableRow key={key}>
              <TableCell>
                <TextField
                  key={textkey}
                  id={`roudid_${id}`}
                  label="Effected Round"
                  type="number"
                  value={i.round}
                  onChange={(e) => {
                    const nrounds = [...rounds];
                    nrounds[index].round = e.target.value;
                    if (_.isFunction(props.callback)) {
                      props.callback(nrounds);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
              <TableCell>
                <SelectValidator
                  key={codekey}
                  labelid="disountcode"
                  id="codekey"
                  label="Prepay Discount Code"
                  style={{
                    marginRight: 10,
                    marginLeft: 10,
                    minWidth: 200,
                  }}
                  value={i.code ? i.code : ""}
                  onChange={(e) => {
                    const nrounds = [...rounds];
                    nrounds[index].code = e.target.value;
                    nrounds[index].discountData = discountCodes.find((i) => {
                      return i.id === e.target.value && i.available;
                    });
                    if (_.isFunction(props.callback)) {
                      props.callback(nrounds);
                    }
                  }}
                  errorMessages={["this field is required"]}
                  validators={["required"]}
                >
                  {discountCodes
                    .filter((i) => i.available)
                    .filter((i) => i.discountDescription.trim())
                    .map((i) => {
                      const key = `menu-item-${Math.floor(Math.random() * 100000)}`;

                      const discountAmount = i.discountAmount;

                      const discountType =
                        i.dollarDiscount === 1
                          ? `${discountAmount}% Off`
                          : `$${discountAmount === null || discountAmount === undefined ? discountAmount : discountAmount.toFixed(2)}${i.dollarDiscount === 3 ? " Fixed Price" : " Off"}`;
                      return (
                        <MenuItem key={key} value={`${i.id}`}>
                          <span
                            style={{
                              color:
                                discountAmount === null ||
                                discountAmount === undefined
                                  ? "red"
                                  : "black",
                            }}
                          >
                            {i.discountDescription} ({discountType})
                          </span>
                        </MenuItem>
                      );
                    })}
                </SelectValidator>
              </TableCell>
              <TableCell>
                <TextField
                  key={textkey}
                  id={`roudid_${id}`}
                  label="Lower SQRFT Range"
                  type="number"
                  value={i.sqftLowerRange}
                  onChange={(e) => {
                    const nrounds = [...rounds];
                    nrounds[index].sqftLowerRange = e.target.value;
                    if (_.isFunction(props.callback)) {
                      props.callback(nrounds);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  key={textkey}
                  id={`roudid_${id}`}
                  label="Upper SQRFT Range"
                  type="number"
                  value={i.sqftUpperRange}
                  onChange={(e) => {
                    const nrounds = [...rounds];
                    nrounds[index].sqftUpperRange = e.target.value;
                    if (_.isFunction(props.callback)) {
                      props.callback(nrounds);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={i.isSlidingScale}
                  onChange={(e) => {
                    const nrounds = [...rounds];
                    nrounds[index].isSlidingScale = e.target.checked;
                    if (_.isFunction(props.callback)) {
                      props.callback(nrounds);
                    }
                  }}
                  name="slidingScale"
                />
              </TableCell>
              <TableCell>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    const nrounds = [...rounds];
                    nrounds.splice(index, 1);
                    if (_.isFunction(props.callback)) {
                      props.callback(nrounds);
                    }
                  }}
                >
                  delete
                </button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

RGDiscountRoundList.propTypes = {
  rounds: PropTypes.array,
  callback: PropTypes.func,
  discountCodes: PropTypes.array,
};

// Our 4 Application mosquito program is applied by My Fertilizing Professionals around your home and throughout your backyard. Our products eliminate annoying mosquitoes on-contact and continue to protect your family for up to 30 days.
