import React, { useState } from "react";
import { MenuItem, Button, FormLabel, Checkbox } from "@material-ui/core";
import { PropTypes } from "prop-types";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";

import botVersions from "./bot-versions";
import botStatuses from "./bot-statuses";
import botTypes from "./bot-types";

export function BotInfoForm(props) {
  const { action = "Save" } = props;

  const loadingImages = [
    { value: "standard", label: "Standard 3 Animated Gif" },
    { value: "no-soil", label: "Standard Minus Soil Gif" },
    { value: "pest-control", label: "Pest Control Animated Gif" },
  ];
  const sqftSource = [
    { value: "zillow", label: "Zillow" },
    { value: "lawnbot-dashboard", label: "ServiceBot Dashboard" },
    { value: "real-green", label: "Real Green" },
    { value: "measur-it", label: "Measur.it" },
  ];

  const buttonStyle = {
    marginTop: 20,
  };

  const submitForm = () => {
    bot.sqFtPercentage = Number.parseFloat(bot.sqFtPercentage);
    delete bot["__typename"];
    props.submit(bot);
  };
  const updateForm = (e) => {
    const key = e.target.id;
    const val = e.target.value;
    const payload = {};
    payload[key] = val;
    setBot({ ...bot, ...payload });
  };

  const localBot = Object.assign({}, props.bot);
  if (!localBot.zipCodes) {
    localBot.zipCodes = "";
  }

  if (!localBot.zipCodes) {
    localBot.dontTaxZipCodes = "";
  }

  const [bot, setBot] = useState({
    ...{
      botTitle: "",
      botName: "",
      botType: "sales",
      botVersion: "1.0",
      botStatus: "Not Started",
      isLive: false,
      sqFtPercentage: 30.0,
      zipCodes: "[]",
      dontTaxZipCodes: "[]",
      hideSummary: false,
      sqftEstimateSource: "measur-it",
      zillowFallback: true,
      isMetric: false,
      measurementMode: "area",
      hideArielAndStreetView: false,
      customSchedulingVerbiage:
        "Your appt has been booked for  <<Scheduled Date>> during the  <<Service Window Desc>>",
    },
    ...localBot,
  });

  return (
    <React.Fragment>
      <ValidatorForm onSubmit={submitForm}>
        <TextValidator
          id="botTitle"
          label="Bot Title"
          value={bot.botTitle}
          style={{ margin: 8, width: 300 }}
          placeholder="My Sales Bot"
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true,
          }}
          validators={["required"]}
        />

        <div>
          <TextValidator
            id="botName"
            label="Name of Your Bot Agent"
            value={bot.botName}
            style={{ margin: 8, width: 300 }}
            placeholder="Sally"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>

        <div>
          <TextValidator
            id="salutation"
            label="How Do You Want To Greet The User?"
            value={bot.salutation || "Hello"}
            style={{ margin: 8, width: 300 }}
            placeholder="Hello"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>

        <div>
          <br />
          <p style={{ marginLeft: 0 }} className="MuiFormLabel-root">
            Greeting
          </p>
          <br />
          <br />

          <textarea
            id="greeting"
            rows={20}
            cols={80}
            value={bot.greeting || ""}
            onChange={updateForm}
          />
        </div>
        <div>
          <SelectValidator
            labelid="botType"
            id="botType"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={bot.botType || "sales"}
            onChange={(e) => {
              const payload = Object.assign({}, bot, {
                botType: e.target.value,
              });
              setBot(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {botTypes.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>
        <div>
          <SelectValidator
            labelid="botVersion"
            id="botVersion"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={bot.botVersion || "1.0"}
            onChange={(e) => {
              const payload = Object.assign({}, bot, {
                botVersion: e.target.value,
              });
              setBot(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {botVersions.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>

          <SelectValidator
            labelid="botStatus"
            id="botStatus"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={bot.botStatus || "Building"}
            onChange={(e) => {
              const payload = Object.assign({}, bot, {
                botStatus: e.target.value,
              });
              setBot(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {botStatuses.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>
        <div style={{ padding: 15 }}>
          <FormLabel>Is Live</FormLabel>
          <Checkbox
            id="active"
            label="Active"
            checked={bot.isLive}
            onChange={(e) => {
              const payload = Object.assign({}, bot, {
                isLive: e.target.checked,
              });
              setBot(payload);
            }}
          />
        </div>

        <div style={{ padding: 15 }}>
          <FormLabel>Hide Ariel &amp; Street View</FormLabel>
          <Checkbox
            id="hideArielAndStreetView"
            label="Hide Ariel And Street View"
            checked={bot.hideArielAndStreetView}
            onChange={(e) => {
              const payload = Object.assign({}, bot, {
                hideArielAndStreetView: e.target.checked,
              });
              setBot(payload);
            }}
          />
        </div>

        {bot && bot.company && bot.company.crmSolution === "servman" && (
          <TextValidator
            id="customSchedulingVerbiage"
            label="Custom Scheduling Verbiage"
            value={
              bot.customSchedulingVerbiage ||
              "Your appt has been booked for  <<Scheduled Date>> during the  <<Service Window Desc>>"
            }
            style={{ margin: 8, width: 300 }}
            placeholder="Your appt has been booked for  <<Scheduled Date>> during the  <<Service Window Desc>>"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        )}

        {bot && bot.company && bot.company.crmSolution !== "servman" && (
          <div style={{ padding: 15 }}>
            <FormLabel>Is Metric</FormLabel>
            <Checkbox
              id="isMetric"
              label="Is Metric"
              checked={bot.isMetric}
              onChange={(e) => {
                const payload = Object.assign({}, bot, {
                  isMetric: e.target.checked,
                });
                setBot(payload);
              }}
            />
            <br />

            <SelectValidator
              labelid="measurementMode"
              id="measurementMode"
              label="Measur.it Measurement Mode"
              style={{
                marginTop: 24,
                marginRight: 10,
                marginLeft: 10,
                width: 300,
              }}
              value={bot.measurementMode || "area"}
              onChange={(e) => {
                const payload = Object.assign({}, bot, {
                  measurementMode: e.target.value,
                });
                setBot(payload);
              }}
              errorMessages={["this field is required"]}
              validators={["required"]}
            >
              {[
                { label: "Area", value: "area" },
                { label: "Perimeter", value: "perimeter" },
              ].map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>
        )}

        {bot && bot.company && bot.company.crmSolution !== "servman" && (
          <div style={{ padding: 15 }}>
            <FormLabel>Hide Pricing Summary</FormLabel>
            <Checkbox
              id="hideSummary"
              label="Hide Pricing Summary"
              checked={bot.hideSummary || false}
              onChange={(e) => {
                const payload = Object.assign({}, bot, {
                  hideSummary: e.target.checked,
                });
                setBot(payload);
              }}
            />
          </div>
        )}
        <div>
          <TextValidator
            id="botSpeed"
            type="number"
            label="Bot Speed (ms) "
            value={bot.botSpeed || 900}
            style={{ margin: 8, width: 180 }}
            placeholder={"1750"}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>
        {bot && bot.company && bot.company.crmSolution !== "servman" && (
          <SelectValidator
            labelid="botQuoteAnalyzingGif"
            id="botQuoteAnalyzingGif"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={bot.botQuoteAnalyzingGif || "standard"}
            onChange={(e) => {
              const payload = Object.assign({}, bot, {
                botQuoteAnalyzingGif: e.target.value,
              });
              setBot(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {loadingImages.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.label}
                </MenuItem>
              );
            })}
          </SelectValidator>
        )}
        <div></div>
        {bot && bot.company && bot.company.crmSolution !== "servman" && (
          <div>
            <div>
              <TextValidator
                id="sqFtPercentage"
                label="Square Footage Reduction Percentage"
                value={!isNaN(bot.sqFtPercentage) ? bot.sqFtPercentage : 30.0}
                style={{ margin: 8, width: 300 }}
                placeholder="30.0"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                errorMessages={["this field is required"]}
                validators={["required"]}
                onChange={updateForm}
                onBlur={updateForm}
              />
              <br />
              <SelectValidator
                labelid="sqftEstimateSource"
                id="sqftEstimateSource"
                label="Square Footage Source"
                style={{
                  marginTop: 24,
                  marginRight: 10,
                  marginLeft: 10,
                  width: 250,
                }}
                value={bot.sqftEstimateSource || "measur-it"}
                onChange={(e) => {
                  const payload = Object.assign({}, bot, {
                    sqftEstimateSource: e.target.value,
                  });
                  setBot(payload);
                }}
                errorMessages={["this field is required"]}
                validators={["required"]}
              >
                {sqftSource.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem key={key} value={i.value}>
                      {i.label}
                    </MenuItem>
                  );
                })}
              </SelectValidator>
            </div>
            <div style={{ padding: 15 }}>
              <FormLabel>Zillow Fallback</FormLabel>
              <Checkbox
                id="zillowFallback"
                label="Zillow Fallback"
                checked={
                  typeof bot.zillowFallback === "undefined"
                    ? true
                    : bot.zillowFallback
                }
                onChange={(e) => {
                  const payload = Object.assign({}, bot, {
                    zillowFallback: e.target.checked,
                  });
                  setBot(payload);
                }}
              />
            </div>
            <div style={{ padding: 15 }}>
              <FormLabel>Lookup Tax From ZipCode</FormLabel>
              <Checkbox
                id="lookupZipCodeTax"
                label="Lookup Taxes By ZipCode"
                checked={
                  bot.lookupZipCodeTax === null ? false : bot.lookupZipCodeTax
                }
                onChange={(e) => {
                  const payload = Object.assign({}, bot, {
                    lookupZipCodeTax: e.target.checked,
                  });
                  setBot(payload);
                }}
              />
            </div>
          </div>
        )}
        <div>
          <br />
          <p>Serviced Zip Codes</p>
          <br />
          <br />
          <textarea
            id="zipCodes"
            rows={20}
            value={bot.zipCodes || ""}
            onChange={updateForm}
          ></textarea>
        </div>
        <div>
          <br />
          <p>{"Don't Tax Zip Codes"}</p>
          <br />
          <br />
          <textarea
            id="dontTaxZipCodes"
            rows={20}
            value={bot.dontTaxZipCodes || ""}
            onChange={updateForm}
          ></textarea>
        </div>
        <div style={{ padding: 15 }}>
          <FormLabel>Direct After Sale</FormLabel>
          <Checkbox
            id="willRedirect"
            label="Zillow Fallback"
            checked={bot.willRedirect === null ? false : bot.willRedirect}
            onChange={(e) => {
              const payload = Object.assign({}, bot, {
                willRedirect: e.target.checked,
              });
              setBot(payload);
            }}
          />
        </div>
        <div>
          <TextValidator
            id="redirectUrl"
            type="text"
            label="Redirect URL "
            value={
              typeof bot.redirectUrl === "undefined"
                ? "https://lawnbot.biz"
                : bot.redirectUrl
            }
            style={{ margin: 8, width: 180 }}
            placeholder={"http://"}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            errorMessages={["this field is required"]}
            validators={[]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={buttonStyle}
        >
          {action} Bot
        </Button>
      </ValidatorForm>
    </React.Fragment>
  );
}

BotInfoForm.propTypes = {
  bot: PropTypes.object,
  submit: PropTypes.func,
};
