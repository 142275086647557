import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
  Snackbar,
} from "@material-ui/core";

import Title from "../../dashboard/Title";

import { Button } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { createNewVersion } from "../../../utils/versionUtilFuncs";
import { fetchAndSetActiveCompanyContext } from "../../../contexts/activeCompany.contexts";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function PestPacPage() {
  const { companyId } = useParams();
  const classes = useStyles();

  const [company, setCompany] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [snackOpened, openSnack] = useState(false);
  const snackMessage = "Settings Saved";

  const fetchCompany = useCallback(async () => {
    try {
      const activeCompany = await fetchAndSetActiveCompanyContext({
        companyId,
      });
      setCompany(activeCompany);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error.message ? error.message : error);
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const savePestPacCompanyData = async () => {
    const payload = { ...company };
    try {
      await createNewVersion(companyId, "company", payload);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  return loaded ? (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">RealGreen</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <ValidatorForm onSubmit={() => {}}>
          <Title>PestPac Settings</Title>
          <div>
            <TextValidator
              id="pestPacTenantID"
              label="Pest Pac Tenant ID"
              value={
                typeof company.pestPacTenantId !== "undefined"
                  ? company.pestPacTenantId
                  : "null"
              }
              style={{ margin: 8, width: 400 }}
              placeholder="000000"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                setCompany({ ...company, pestPacTenantId: e.target.value });
              }}
              onBlur={(e) => {
                setCompany({ ...company, pestPacTenantId: e.target.value });
              }}
            />
          </div>

          <Button
            color="primary"
            variant="contained"
            onClick={savePestPacCompanyData}
          >
            Save
          </Button>
        </ValidatorForm>
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={2000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Container>
  ) : null;
}
