import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import * as versionUtils from "../../../utils/versionUtilFuncs";
const { v4: uuidv4 } = require("uuid");

const placeholderDescriptions = {
  mowing:
    "Enjoy your professionally manicured lawn all summer long! Each visit includes mowing, edging, line trimming, & clean-up. Clippings will be mulched with this service returning key nutrients to the lawn.",
  mosquito:
    "Protect your yourself, guests, and pets from potential diseases mosquitoes carry. We will treat all the places mosquitoes potentially live and rest on your property. Start living in comfort by enjoying the outdoors without the bites.",
  "fertilizer & weed control":
    "Early application of Pre-Emergent to prevent weeds and post-emergents for weeds that are up and active. Spring Application of Pre-Emergent and Post-Emergent Weed Control* kills all weeds in lawn and prevents new ones from germinating. Early Summer application of fertilizer treatment, timed to feed your lawn as it begins its green-up. Mid-Summer Application of a Balanced Fertilizer keeps lawn healthy and resistant to disease. Late Summer Application of Fertilizer provides long-lasting nutrition to prepare for cooler months ahead. Early Fall application of Pre-Emergent prevents cool-weather and winter weeds. Lime Application restores pH balance to acidic soil, maintaining optimal conditions for healthy growth. Early Winter Granular Application of Pot Ash Fertilizer encourages root growth and strength throughout winter, keeping lawn resilient against cold.",
  "sprinkler maintenance":
    "Our Sprinkler Maintenance Package includes two visits and is a great way to make sure your lawn looks and stays green. During our visit, our services will include: A full check of the system including head adjustment, an adjustment of the watering schedules on the controller, and a thorough check of sprinkler heads, pipes and valves for leaks.",
  "mole control":
    "Moles create tunnel systems under the ground, damaging the roots of the lawn and causing ugly “speedbumps” on the surface for you to look at. We have a unique approach when it comes to removing moles. First, we remove their food source—grubs—and replace them with an active bait for an effective solution that our customers love.",
  "aeration & overseed":
    "Overseeding is the planting of grass seed directly into existing turf, without tearing up the turf, or the soil. It's an easy way to fill in bare spots, improve the density of turf, establish improved grass varieties and enhance your lawn's color.",
  "mosquito & tick":
    "A five application program that uses high pressure spray applications of an all natural essential oil blend.  It controls insects on contact and acts as a repellant for about 30 days. Each application is 5-7 weeks apart throughout the season. This application does not harm people, pets, or the environment, and your property is ready for outdoor living 20 minutes after the application is made. It leaves an outdoor aromatherapy scent after each application. ",
  "grub control":
    "Prevent Grubs from destroying your lawn. The grubs will eat the roots out from under your lawn and can encourage additional damage from raccoons, possums and skunks who are out foraging for food.",
  "flea & tick":
    "Fleas may be small, but the impact they have on your life can be huge. If you're dealing with flea bites, one thing is for sure: you'll want them gone as quickly as possible. However, fleas are difficult to get rid of, which is why when homeowners find themselves with a flea problem, they quickly realize that they need to call us for professional help!",
  aeration:
    "We operate a machine that pulls up small plugs of dirt and redistributes them over the lawn. Pulling up these plugs allows the soil to spread out and de-compact, promoting the flow of nutrition to the roots.",
  "irrigation winter":
    "Just need help with the seasonal maintenance? The All-Terrain team can take care of winterizing your sprinkler system to prevent burst lines and other damage over the winter, and we can get it up and running in the Spring.",
  "fire ant":
    "Provides single application control of Fire Ants for up to ONE YEAR. Controls existing colonies and prevents future infestations.",
  "pest control":
    "We will treat your home for general pests on a seasonal basis, one time in each season. We provide an exterior barrier that prevents pests from entering the home from the outside. In the event that you have a problem inside, simply call the office and we’ll arrange a time that’s convenient for you and take care of the problem.",
  "lawn seeding":
    "We aerate your lawn to breathe new life into it, then sprinkle seed over the lawn & apply starter fertilizer to thicken the turf and make a more robust and healthier lawn. Performed in the fall.",
  "irrigation spring start-up":
    "A complete irrigation inspection includes a thorough walk through of the entire system checking all of your heads for proper coverage, checking your timer box for proper times an function, inspecting zone valves and solenoids etc.",
};
function getProgramDescription(type) {
  return placeholderDescriptions[type.toLowerCase()];
}
export async function cloneCompany(
  activeCompany,
  name,
  setProgress,
  setProgressMessage,
) {
  try {
    if (activeCompany) {
      setProgress(5);
      const company = { ...activeCompany };
      delete company.__typename;
      const newCompanyId = `${uuidv4()}`;
      const newDataServicesId = versionUtils.createInitialId(newCompanyId);
      const newCompanyBrandingId = versionUtils.createInitialId(newCompanyId);
      const bot = {
        ...company.bots.items[0],
        botCompanyId: newCompanyId,
        id: versionUtils.createInitialId(newCompanyId),
      };
      delete bot.__typename;
      setProgress(10);
      const dataServices = {
        ...company.dataServices,
        companyDataServicesCompanyId: newCompanyId,
        id: newDataServicesId,
        airTableBase: "null",
        realGreenAPIKey: "null",
        serviceAssistantId: "",
        stripeSecret: "null",
        stripeTestSecret: "null",
        zillowAPIKey: "null",
      };
      delete dataServices.__typename;
      const branding = {
        ...company.branding,
        companyBrandingCompanyId: newCompanyId,
        id: newCompanyBrandingId,
        tagLine: "",
        valueAddDescription: "",
        instaQuoteMessage: "Welcome to instaquote",
        quoteModalImage: "",
        aboutUsAssetType: "",
        aboutUsAssetUrl: "",
        valueAddImage:
          "https://lawnbot-assets.s3.us-east-2.amazonaws.com/value_add_placeholder.png",
        companyAvatar:
          "https://lawnbot-assets.s3.us-east-2.amazonaws.com/avatar_placeholder.png",
        companyLogo:
          "https://lawnbot-assets.s3.us-east-2.amazonaws.com/branding_placeholder.png",
      };
      delete branding.__typename;
      const programs = company.programs?.items
        ? [...company.programs.items]
        : [];
      const offers = company.offers?.items ? [...company.offers.items] : [];
      const priceTables = company.priceTables?.items
        ? [...company.priceTables.items]
        : [];

      versionUtils.runKeyDeletion(company, "company");
      const newCompany = {
        ...company,
        id: newCompanyId,
        companyName: name,
        customerName: name,
        customerAddress: "UPDATE THE ADDRESS",
        customerEmail: "nowantthese@lawnbot.biz",
        customerCity: "UPDATE THE CITY",
        customerPhone: "0000000000",
        customerZip: "00000",
        companyDataServicesId: newDataServicesId,
        companyBrandingId: newCompanyBrandingId,
        approved: false,
        approvedBy: "",
        approvedOn: null,
      };
      delete newCompany.__typename;

      // biome-ignore lint/complexity/noForEach: <explanation>
      Object.keys(newCompany).forEach((key) => {
        if (key.startsWith("crm")) {
          delete newCompany[key];
        }
      });
      versionUtils.runKeyDeletion(bot, "bot");
      versionUtils.runKeyDeletion(branding, "companyBranding");
      versionUtils.runKeyDeletion(dataServices, "dataServices");
      const servicesByProgramId = {};
      const probingQuestionsByProgramId = {};
      const priceTableRowsByPriceTableId = {};
      setProgress(15);
      // setup for programs and prepare for services and probing questions
      // biome-ignore lint/complexity/noForEach: <explanation>
      programs.forEach((program, index) => {
        const newId = versionUtils.createInitialId(newCompanyId);
        servicesByProgramId[program.id] = {
          newId,
          services: [],
        };
        probingQuestionsByProgramId[program.id] = {
          newId,
          probingQuestions: {},
        };

        const placeholderDescription = getProgramDescription(
          program.programType,
        );
        const programDescription = program.programDescription?.trim()
          ? program.programDescription
          : placeholderDescription;

        const updatedProgram = {
          ...program,
          crmProgamCode: "",
          programDescription,
          id: newId,
          programCompanyId: newCompanyId,
          realGreenApplicationDiscountCode: "",
          realGreenPrepayDiscountCode: "",
          useRealGreenPricingEngine: false,
        };

        versionUtils.runKeyDeletion(updatedProgram);

        programs[index] = updatedProgram;
      });

      // setup the offers
      console.log(">>>>>>>>>>>>>>offers", offers);
      // biome-ignore lint/complexity/noForEach: <explanation>
      offers.forEach((offer) => {
        versionUtils.runKeyDeletion(offer, "");
        offer.offerCompanyId = newCompanyId;
        offer.id = versionUtils.createInitialId(newCompanyId);
        const offerPrograms = JSON.parse(offer.programs);
        // biome-ignore lint/complexity/noForEach: <explanation>
        offerPrograms.forEach((offerProgram) => {
          offerProgram.programId = servicesByProgramId[offerProgram.programId]
            ? servicesByProgramId[offerProgram.programId].newId
            : "";
        });
        // only return offers that are tied to programs that still exist
        offerPrograms.filter((offerProgram) => offerProgram.programId);
        offer.programs = JSON.stringify(offerPrograms);
      });

      // set up for priceTables and prepare to grab priceTable rows
      // biome-ignore lint/complexity/noForEach: <explanation>
      priceTables.forEach((priceTable, index) => {
        const newId = versionUtils.createInitialId(newCompanyId);
        priceTableRowsByPriceTableId[priceTable.id] = {
          newId,
          priceTableRow: {},
        };

        const updatedPriceTable = {
          ...priceTable,
          id: newId,
          priceTableCompanyId: newCompanyId,
        };

        versionUtils.runKeyDeletion(updatedPriceTable, "priceTable");
        priceTables[index] = updatedPriceTable;
      });

      setProgress(25);
      // grab all the services and probing questions for programs
      for (const key in servicesByProgramId) {
        try {
          const fullProgram = await API.graphql(
            graphqlOperation(queries.getProgramVersioned, {
              id: key,
              version: "v0",
            }),
          );

          if (fullProgram.data.getProgramVersioned.probingQuestions) {
            const probingQuestions =
              fullProgram.data.getProgramVersioned.probingQuestions;
            probingQuestions.id = versionUtils.createInitialId(
              servicesByProgramId[key].newId,
            );
            versionUtils.runKeyDeletion(probingQuestions, "probingQuestion");
            probingQuestions.probingQuestionsProgramId =
              probingQuestionsByProgramId[key].newId;
            const program = programs.find(
              (program) =>
                program.id === probingQuestions.probingQuestionsProgramId,
            );
            program.programProbingQuestionsId = probingQuestions.id;
            probingQuestionsByProgramId[key].probingQuestions =
              probingQuestions;
          }

          const services = fullProgram.data.getProgramVersioned.services
            ? fullProgram.data.getProgramVersioned.services.items
            : [];
          // biome-ignore lint/complexity/noForEach: <explanation>
          services.forEach((service) => {
            service.id = versionUtils.createInitialId(
              servicesByProgramId[key].newId,
            );
            service.serviceProgramId = servicesByProgramId[key].newId;
            service.priceTableId =
              priceTableRowsByPriceTableId[service.priceTableId].newId;
            versionUtils.runKeyDeletion(service, "service");
          });

          servicesByProgramId[key].services = services;
        } catch (err) {
          return {
            err: true,
            message: err.errors?.[0]
              ? err.errors[0].message
              : err.message
                ? err.message
                : err,
          };
        }
      }
      setProgress(30);
      // grab all the priceTableRows
      for (const key in priceTableRowsByPriceTableId) {
        try {
          const fullPriceTable = await API.graphql(
            graphqlOperation(queries.getPriceTableVersioned, {
              id: key,
              version: "v0",
            }),
          );
          const rows = fullPriceTable.data.getPriceTableVersioned.rows
            ? fullPriceTable.data.getPriceTableVersioned.rows.items
            : [];
          // biome-ignore lint/complexity/noForEach: <explanation>
          rows.forEach((row) => {
            row.id = `${uuidv4()}`;
            row.priceTableRowsPriceTableId =
              priceTableRowsByPriceTableId[key].newId;
            versionUtils.runKeyDeletion(row, "");
          });
          priceTableRowsByPriceTableId[key].priceTableRows = rows;
        } catch (err) {
          return {
            err: true,
            message: err.errors?.[0]
              ? err.errors[0].message
              : err.message
                ? err.message
                : err,
          };
        }
      }
      setProgress(40);
      // now upload company, bot, companybranding, dataServices, programs, services, priceTables, priceTableRows, probingQuestions, offers
      setProgressMessage("Cloning company...");
      await versionUtils.createInitialVersions("company", newCompany);
      setProgressMessage("Cloning bot...");
      await versionUtils.createInitialVersions("bot", bot);
      setProgress(45);
      setProgressMessage("Cloning company branding...");
      await versionUtils.createInitialVersions("companyBranding", branding);
      setProgress(50);
      setProgressMessage("Cloning data services...");

      delete dataServices.__typename;
      await API.graphql(
        graphqlOperation(mutations.createCompanyDataServices, {
          input: dataServices,
        }),
      );
      setProgress(55);
      setProgressMessage("Cloning price tables...");
      // await versionUtils.createInitialVersions("program", programs[0]);
      for (let i = 0; i < programs.length; i++) {
        const v0 = await versionUtils.createInitialVersions("program", {
          ...programs[i],
        });
        // won't be linked to company until this happens for some reason
        if (v0?.id) {
          await versionUtils.createNewVersion(v0.id, "program", v0);
        }
      }
      setProgress(60);
      setProgressMessage("Cloning price tables...");
      for (let i = 0; i < priceTables.length; i++) {
        const v0 = await versionUtils.createInitialVersions(
          "priceTable",
          priceTables[i],
        );
        // won't be linked to company until this happens for some reason
        if (v0?.id) {
          await versionUtils.createNewVersion(v0.id, "priceTable", v0);
        }
      }
      setProgress(65);
      setProgressMessage("Cloning services...");
      for (const key in servicesByProgramId) {
        for (let i = 0; i < servicesByProgramId[key].services.length; i++) {
          const v0 = await versionUtils.createInitialVersions(
            "service",
            servicesByProgramId[key].services[i],
          );
          // won't be linked to program until this happens for some reason
          if (v0?.id) {
            await versionUtils.createNewVersion(v0.id, "service", v0);
          }
        }
      }
      setProgress(70);
      setProgressMessage("Cloning price table rows...");
      for (const key in priceTableRowsByPriceTableId) {
        for (
          let i = 0;
          i < priceTableRowsByPriceTableId[key].priceTableRows.length;
          i++
        ) {
          delete priceTableRowsByPriceTableId[key].priceTableRows[i].__typename;
          await API.graphql(
            graphqlOperation(mutations.createPriceTableRows, {
              input: priceTableRowsByPriceTableId[key].priceTableRows[i],
            }),
          );
        }
      }
      setProgress(75);
      setProgressMessage("Cloning probing questions...");
      for (const key in probingQuestionsByProgramId) {
        if (
          Object.keys(probingQuestionsByProgramId[key].probingQuestions)
            .length !== 0
        ) {
          const clonedProbingQuestions =
            probingQuestionsByProgramId[key].probingQuestions;
          delete clonedProbingQuestions.__typename;
          await API.graphql(
            graphqlOperation(mutations.createProbingQuestions, {
              input: clonedProbingQuestions,
            }),
          );
        }
      }
      setProgress(80);
      setProgressMessage("Cloning offers...");
      for (let i = 0; i < offers.length; i++) {
        delete offers[i].__typename;
        await API.graphql(
          graphqlOperation(mutations.createOffer, {
            input: offers[i],
          }),
        );
      }
      setProgress(100);
      return { err: false, message: newCompany.id };
    }
    console.log("Active company not found");
    return { err: true, message: "company not found" };
  } catch (err) {
    console.log(">>>>>>>>>>>inside catch", err);
    return {
      err: true,
      message: err.errors?.[0]
        ? err.errors[0].message
        : err.message
          ? err.message
          : err,
    };
  }
}
