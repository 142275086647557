import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  TableContainer,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import { fade } from "@material-ui/core/styles/colorManipulator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { styled } from "@material-ui/core/styles";

import useDebounce from "../../utils/debounce";
import useListCompanies from "../../hooks/companies/useListCompanies";
import Title from "../dashboard/Title";
import { setDefaultActiveCompanyContext } from "../../contexts/activeCompany.contexts";

// Styled components for better organization
const LoadingOverlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  headerSection: {
    marginBottom: theme.spacing(2),
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "stretch",
      gap: theme.spacing(1),
    },
  },
  controlsRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: fade(theme.palette.primary.main, 0.02),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(1.5),
    },
  },
  searchField: {
    flex: 4,
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create(["box-shadow", "border-color"]),
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused": {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 2px ${fade(theme.palette.primary.main, 0.1)}`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  controlsGroup: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  pageSizeControl: {
    minWidth: 200,
    flex: 1,
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.paper,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  newButton: {
    marginLeft: "auto",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  tableWrapper: {
    position: "relative",
    minHeight: 200,
    width: "100%",
    overflow: "hidden",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    width: "100%",
    overflowX: "auto",
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.background.default,
      fontWeight: 600,
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1.5, 2),
      textAlign: "left",
      "&:last-child": {
        paddingRight: theme.spacing(2),
      },
    },
    "& .MuiTableCell-body": {
      padding: theme.spacing(1.5, 2),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "&:last-child": {
        paddingRight: theme.spacing(2),
      },
    },
    "& .MuiTableRow-root:last-child .MuiTableCell-body": {
      borderBottom: "none",
    },
    "& .MuiTableRow-hover:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.02),
    },
  },
  logoCell: {
    width: "12%",
    maxWidth: 120,
    minWidth: 100,
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  companyNameCell: {
    width: "33%",
  },
  contactCell: {
    width: "40%",
  },
  actionCell: {
    width: "15%",
    minWidth: 100,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px !important`,
    [theme.breakpoints.down("sm")]: {
      width: "20%",
      minWidth: 80,
    },
  },
  companyLogo: {
    width: 60,
    height: 60,
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
  },
  companyName: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  contactInfo: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    "& > *": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  actionButton: {
    minWidth: "auto",
    width: "100%",
    height: "auto",
    padding: theme.spacing(0.75, 1),
    lineHeight: 1.2,
    "& .MuiButton-label": {
      display: "block",
      whiteSpace: "normal",
      wordBreak: "break-word",
      textAlign: "center",
      fontSize: "0.8125rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  loadMoreContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  emptyState: {
    textAlign: "center",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  },
  recordCount: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
}));

export default function CompanyListTable(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    companies,
    lastKey,
    loadMore,
    loading,
    pageSize,
    setPageSize,
    totalCount,
  } = useListCompanies();
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filterText, setFilterText] = useState("");

  const debouncedSearchTerm = useDebounce(filterText, 500);

  useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  useEffect(() => {
    if (companies.length) {
      const lowerCaseSearchTerm = debouncedSearchTerm.trim().toLowerCase();
      const comps = companies.filter(
        (i) =>
          i.companyName.toLowerCase().includes(lowerCaseSearchTerm) ||
          i.customerName.toLowerCase().includes(lowerCaseSearchTerm) ||
          i.customerEmail.toLowerCase().includes(lowerCaseSearchTerm),
      );

      setFilteredCompanies(comps);
    }
  }, [companies, debouncedSearchTerm]);

  useEffect(() => {
    setDefaultActiveCompanyContext();
  }, []);

  const stripImageMeta = (src) => {
    return src ? src.split("?")[0] : "";
  };

  const searchRecords = (e) => {
    setFilterText(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>
        <div className={classes.titleRow}>
          <Title className={classes.title} id="company-management-title">
            Company Management
          </Title>
          <Typography
            className={classes.recordCount}
            variant="body2"
            id="company-count-display"
          >
            {loading && !companies.length
              ? "Loading..."
              : `Showing ${filteredCompanies.length} of ${totalCount} companies`}
          </Typography>
        </div>

        <Paper
          className={classes.controlsRow}
          elevation={0}
          id="company-controls"
        >
          <TextField
            id="company-search-field"
            className={classes.searchField}
            onChange={searchRecords}
            placeholder={
              isMobile
                ? "Search companies..."
                : "Search by company name, customer name, or email..."
            }
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              "aria-label": "search companies",
            }}
          />

          <div className={classes.controlsGroup} id="company-controls-group">
            <FormControl
              variant="outlined"
              size="small"
              className={classes.pageSizeControl}
            >
              <InputLabel id="page-size-select-label">
                Companies per page
              </InputLabel>
              <Select
                labelId="page-size-select-label"
                id="page-size-select"
                value={pageSize}
                onChange={handlePageSizeChange}
                label="Companies per page"
              >
                <MenuItem value={25}>25 companies</MenuItem>
                <MenuItem value={50}>50 companies</MenuItem>
                <MenuItem value={100}>100 companies</MenuItem>
                <MenuItem value={-1}>All companies</MenuItem>
              </Select>
            </FormControl>

            <Button
              id="add-company-button"
              className={classes.newButton}
              color="primary"
              variant="contained"
              component={Link}
              to="/company/new"
              startIcon={<AddIcon />}
            >
              {isMobile ? "Add" : "Add Company"}
            </Button>
          </div>
        </Paper>
      </div>

      <div className={classes.tableWrapper} id="company-table-wrapper">
        {loading && (
          <LoadingOverlay id="loading-overlay">
            <StyledCircularProgress size={32} thickness={4} />
          </LoadingOverlay>
        )}

        {companies.length ? (
          <TableContainer
            className={classes.tableContainer}
            id="company-table-container"
          >
            <Table
              className={classes.table}
              size={isMobile ? "small" : "medium"}
              id="company-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.logoCell} id="header-logo">
                    Brand Logo
                  </TableCell>
                  <TableCell
                    className={classes.companyNameCell}
                    id="header-company-name"
                  >
                    Company Name
                  </TableCell>
                  <TableCell
                    className={classes.contactCell}
                    id="header-contact"
                  >
                    Primary Contact
                  </TableCell>
                  <TableCell className={classes.actionCell} id="header-actions">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="company-table-body">
                {filteredCompanies.map((row) => (
                  <TableRow key={row.id} hover>
                    <TableCell className={classes.logoCell}>
                      {row.companyLogo ? (
                        <img
                          className={classes.companyLogo}
                          src={stripImageMeta(row.companyLogo)}
                          alt={`${row.companyName} logo`}
                        />
                      ) : (
                        <Typography variant="caption" color="textSecondary">
                          No logo
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell className={classes.companyNameCell}>
                      <Typography
                        className={classes.companyName}
                        variant="subtitle2"
                        title={row.companyName}
                      >
                        {row.companyName}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.contactCell}>
                      <div className={classes.contactInfo}>
                        <Typography variant="body2" title={row.customerName}>
                          {row.customerName}
                        </Typography>
                        {row.customerEmail && (
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            title={row.customerEmail}
                          >
                            {row.customerEmail}
                          </Typography>
                        )}
                      </div>
                    </TableCell>
                    <TableCell className={classes.actionCell}>
                      <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`/company/${row.id}`}
                        size="small"
                      >
                        {isMobile ? "View" : "View\nDetails"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper className={classes.emptyState} id="empty-state">
            <Typography variant="h6" gutterBottom id="empty-state-title">
              No companies found
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              id="empty-state-subtitle"
            >
              Add a new company to get started
            </Typography>
          </Paper>
        )}

        {!loading && lastKey && filteredCompanies.length >= pageSize && (
          <div className={classes.loadMoreContainer} id="load-more-container">
            <Button
              id="load-more-button"
              color="primary"
              variant="outlined"
              onClick={() => loadMore()}
              startIcon={loading && <StyledCircularProgress size={20} />}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More Results"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

CompanyListTable.propTypes = {
  customers: PropTypes.array,
  classes: PropTypes.object,
};
