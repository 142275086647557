import React from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { createProbingQuestions } from "../../../graphql/mutations";
import { ProgramInfoForm } from "./program-info-form";
import {
  createInitialVersions,
  createInitialId,
  createNewVersion,
} from "../../../utils/versionUtilFuncs";
import getProgramTypeTemplateQuestions from "../../../utils/getProgramTypeTemplateQuestions";
import { useHistory } from "react-router-dom";
import { useGetActiveCompanyContext } from "../../../hooks/companies/useGetActiveCompanyContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyProgramNew() {
  const { companyId } = useParams();
  const { company } = useGetActiveCompanyContext({ companyId });
  const classes = useStyles();
  const history = useHistory();

  const submitProgram = async (program) => {
    const probingQuestions = {
      sectionName: `probing-questions-${Math.floor(Math.random() * 10000)}`,
      entryId: `entry-${Math.floor(Math.random() * 10000)}`,
      nextSectionId: "next-probing-section",
    };
    const id = createInitialId(companyId);
    probingQuestions.probingQuestionsProgramId = id;
    // biome-ignore lint/performance/noDelete: <explanation>
    delete probingQuestions.__typename;

    try {
      const fetch2 = await API.graphql(
        graphqlOperation(createProbingQuestions, { input: probingQuestions }),
      );

      const pid = fetch2.data.createProbingQuestions.id;
      const payload = {
        ...program,
        id,
        programProbingQuestionsId: pid,
        programCompanyId: companyId,
      };

      await createInitialVersions("program", payload);

      if (program.useProbingQuestions) {
        const predefinedProbingQuestions = getProgramTypeTemplateQuestions(
          program.programType,
        );
        if (
          !(
            predefinedProbingQuestions.length === 1 &&
            predefinedProbingQuestions[0].id === "questions"
          )
        ) {
          const probingQuestionsPayload = {
            ...program,
            probingQuestionsJson: JSON.stringify(
              predefinedProbingQuestions,
              null,
              2,
            ),
          };
          try {
            await createNewVersion(id, "program", probingQuestionsPayload);
          } catch (error) {
            console.log(
              "Error when setting predefined probing questions: ",
              error,
            );
          }
        }
      }

      alert("Program Saved!");
      history.push(`/company/${companyId}/program/${id}`);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/programs`}>
          Programs
        </Link>
        <Typography color="textPrimary">New Program</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>New Program</Title>
      </div>
      <div className={classes.container}>
        <ProgramInfoForm
          priceTables={company?.priceTables?.items || []}
          submit={submitProgram}
        />
      </div>
    </Container>
  );
}
