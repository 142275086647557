import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";

const useListCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);

  const fetchPageCompanies = useCallback(async () => {
    try {
      setLoading(true);

      let queryString = `pageSize=${pageSize}`;
      if (pageSize !== -1) {
        const {
          id: lastEvaluatedKeyId,
          companyName: lastEvaluatedKeyCompanyName,
          version: lastEvaluatedKeyVersion,
        } = lastKey || {};

        if (lastEvaluatedKeyId) {
          queryString += `&lastEvaluatedKeyId=${lastEvaluatedKeyId}`;
        }
        if (lastEvaluatedKeyCompanyName) {
          queryString += `&lastEvaluatedKeyCompanyName=${lastEvaluatedKeyCompanyName}`;
        }
        if (lastEvaluatedKeyVersion) {
          queryString += `&lastEvaluatedKeyVersion=${lastEvaluatedKeyVersion}`;
        }
      }

      const {
        companies: fetchedCompanies,
        lastEvaluatedKey,
        totalCount,
      } = await API.get("bridgeApi", `/v2/companies?${queryString}`);

      const sortedCompanies =
        pageSize === -1
          ? [...fetchedCompanies]
          : [...companies, ...fetchedCompanies];

      sortedCompanies.sort((a, b) =>
        a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase()),
      );

      setCompanies(sortedCompanies);
      setLastKey(lastEvaluatedKey);
      setTotalCount(totalCount);
      setLoading(false);
      return sortedCompanies;
    } catch (error) {
      console.log("Error fetching companies:", error);
      setLoading(false);
      return [];
    }
  }, [companies, lastKey, pageSize]);

  const resetAndFetch = useCallback(async () => {
    setCompanies([]);
    setLastKey(null);
    setLoading(true);

    const queryString = `pageSize=${pageSize}`;
    try {
      const {
        companies: fetchedCompanies,
        lastEvaluatedKey,
        totalCount,
      } = await API.get("bridgeApi", `/v2/companies?${queryString}`);

      const sortedCompanies = [...fetchedCompanies];
      sortedCompanies.sort((a, b) =>
        a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase()),
      );
      setCompanies(sortedCompanies);
      setLastKey(lastEvaluatedKey);
      setTotalCount(totalCount);
    } catch (error) {
      console.log("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  }, [pageSize]);

  useEffect(() => {
    resetAndFetch();
  }, [resetAndFetch]);

  return {
    companies,
    lastKey,
    loadMore: fetchPageCompanies,
    loading,
    pageSize,
    setPageSize,
    totalCount,
  };
};

export default useListCompanies;
