import { useSnapshot } from "valtio";
import {
  activeCompanyContext,
  fetchAndSetActiveCompanyContext,
} from "../../contexts/activeCompany.contexts";
import { useEffect } from "react";

export const useGetActiveCompanyContext = ({ companyId }) => {
  const proxyCompany = useSnapshot(activeCompanyContext);
  const company = JSON.parse(JSON.stringify(proxyCompany));

  useEffect(() => {
    if (companyId) {
      const fetchCompany = async ({ companyId }) => {
        try {
          const fetchedCompany = await fetchAndSetActiveCompanyContext({
            companyId,
          });
          return fetchedCompany;
        } catch (error) {
          console.log(
            "error: ",
            error?.errors ? error.errors[0].message : error,
          );
        }
      };

      fetchCompany({ companyId });
    }
  }, [companyId]);

  return company;
};
